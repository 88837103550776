import { AnimatePresence } from "framer-motion";
import React, { lazy, useEffect } from "react";
import { useMatches, useOutlet, useRouteError } from "react-router-dom";
import { api, toErrScroll, isset } from "../util/util";
import { API_CONNECTION, SYSTEM_CONST } from "../config/Const";
import lazyImport from "../util/lazyImport.tsx";
import useScrollToTop from "../component/useScrollToTop.js";
import ScrollToTop from "../component/useScrollToTop.js";
import { useRecoilState, useSetRecoilState } from "recoil";
import {
  showImgLoaderAtom,
  showLoaderAtom,
  showSplashLoaderAtom,
} from "../store/atom.js";

//遅延ロード
const MemQuit = lazyImport(() => import("../page/mpanel/MemQuit"));
const MemMain = lazyImport(() => import("../page/mpanel/MemMain"));
const MemPayment = lazyImport(() => import("../page/mpanel/MemPayment"));
const MemProfile = lazyImport(() => import("../page/mpanel/MemProfile"));
const MemWelcome = lazyImport(() => import("../page/mpanel/MemWelcome"));
const Mpanel = lazyImport(() => import("../page/mpanel/Mpanel"));
const MemPlanEntry = lazyImport(() => import("../page/mpanel/MemPlanEntry"));
const MemPlanChange = lazyImport(() => import("../page/mpanel/MemPlanChange"));
const MemPlanRestart = lazyImport(() =>
  import("../page/mpanel/MemPlanRestart")
);
const MemService = lazyImport(() => import("../page/me/MemService"));
const MemEntry = lazyImport(() => import("../page/me/MemEntry"));
const MemToken = lazyImport(() => import("../page/me/MemToken"));
const MemInit = lazyImport(() => import("../page/me/MemInit"));
const MemPwResetMail = lazyImport(() => import("../page/me/MemPwResetMail"));
const MemPwReset = lazyImport(() => import("../page/me/MemPwReset"));
const MemLogin = lazyImport(() => import("../page/me/MemLogin"));
const MemCya = lazyImport(() => import("../page/me/MemCya"));
const CreEntry = lazyImport(() => import("../page/cre/CreEntry"));
const CreLogin = lazyImport(() => import("../page/cre/CreLogin"));
const CreCya = lazyImport(() => import("../page/cre/CreCya"));
const Layout = lazyImport(() => import("../layout/MainLayout"));
const CreProfile = lazyImport(() => import("../page/cpanel/CreProfile"));
const Cpanel = lazyImport(() => import("../page/cpanel/Cpanel"));
const CreToken = lazyImport(() => import("../page/cre/CreToken"));
const CreInit = lazyImport(() => import("../page/cre/CreInit"));
const CreLinks = lazyImport(() => import("../page/cpanel/CreLinks"));
const CrePwResetMail = lazyImport(() => import("../page/cre/CrePwResetMail"));
const CrePwReset = lazyImport(() => import("../page/cre/CrePwReset"));
const GenError = lazyImport(() => import("../page/error/GenError"));
const CreTest = lazyImport(() => import("../page/cpanel/CreTest"));
const CreMain = lazyImport(() => import("../page/cpanel/CreMain"));
const FreeMain = lazyImport(() => import("../page/free/FreeMain"));
const CreReqs = lazyImport(() => import("../page/cpanel/CreReqs"));
const CreReqsTotal = lazyImport(() => import("../page/cpanel/CreReqsTotal"));
const CreFans = lazyImport(() => import("../page/cpanel/CreFans"));
const CreWish = lazyImport(() => import("../page/cpanel/CreWish"));
const CreWishlist = lazyImport(() => import("../page/cpanel/CreWishlist"));
const CrePlatforms = lazyImport(() => import("../page/cpanel/CrePlatforms"));
const CreUrls = lazyImport(() => import("../page/cpanel/CreUrls"));
const CreOpchat = lazyImport(() => import("../page/cpanel/CreOpchat"));
const CreOpchatReservs = lazyImport(() =>
  import("../page/cpanel/CreOpchatReservs")
);
const CreOpchatBlocks = lazyImport(() =>
  import("../page/cpanel/CreOpchatBlocks")
);
const CreSettings = lazyImport(() => import("../page/cpanel/CreSettings"));
const CreLoginSetting = lazyImport(() =>
  import("../page/cpanel/CreLoginSetting")
);
const CreFanSetting = lazyImport(() => import("../page/cpanel/CreFanSetting"));
const CreQuit = lazyImport(() => import("../page/cpanel/CreQuit"));
const Contact = lazyImport(() => import("../page/office/Contact"));
const PrmMain = lazyImport(() => import("../page/premium/PrmMain"));
const PrmChat = lazyImport(() => import("../page/premium/PrmChat"));
const PrmReqsTotal = lazyImport(() => import("../page/premium/PrmReqsTotal"));
const PrmOpChat = lazyImport(() => import("../page/premium/PrmOpChat"));

const LoaderSmaple = lazyImport(() => import("../page/LoaderSmaple.js"));
const DialogSmaple = lazyImport(() => import("../page/DialogSmaple.js"));

const App = () => {
  const matches = useMatches();
  const [showLoader, setShowLoader] = useRecoilState(showLoaderAtom);

  if (!isset(matches[1])) {
    window.location.href = "/err/06";
    matches[1] = "";
  }

  //tayori aiチャット
  let tayorikey = null;

  if (matches[1].pathname.match(/^\/cpanel/g)) {
    tayorikey = SYSTEM_CONST.TAYORI_AI_KEY_CRE;
  } else if (matches[1].pathname.match(/^\/mpanel/g)) {
    tayorikey = SYSTEM_CONST.TAYORI_AI_KEY_MEM;
  }

  const tayorichat = document.getElementById("tayori-ai-chatbot-app");
  const tayoriscript = document.getElementById("tayori-ai-script");

  if (tayorikey) {
    if (!tayorichat && !tayoriscript) {
      const scriptTag = document.createElement("script");
      scriptTag.type = "text/javascript";
      scriptTag.async = true;
      scriptTag.id = "tayori-ai-script";
      scriptTag.src =
        "https://ai-chatbot.tayori.com/assets/chat/index.js?key=" + tayorikey;

      const firstScriptTag = document.getElementsByTagName("script")[0];
      firstScriptTag.parentNode.insertBefore(scriptTag, firstScriptTag);
    }
  } else {
    if (tayorichat) {
      tayorichat.parentNode.removeChild(tayorichat);
      tayoriscript.parentNode.removeChild(tayoriscript);
    }
  }

  useEffect(() => {
    //ローダー初期化
    setShowLoader(false);
    // setShowImgLoader(false);
    // setShowSplashLoader(false);
  }, [matches]);

  const outlet = useOutlet();
  return (
    <Layout>
      <ScrollToTop />
      <AnimatePresence mode="wait">
        <React.Fragment key={matches[1].pathname}>{outlet}</React.Fragment>
      </AnimatePresence>
    </Layout>
  );
};

const ErrorBoundary = () => {
  const error = useRouteError();
  console.dir(error);

  return <GenError errCode="99" errMes={error.response.data.message} />;
};

const router = [
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: "cpanel/",
        element: <Cpanel />,
        loader: ({ params }) => {
          return api({ url: `${API_CONNECTION.URL}api/cpanel/user` });
        },
        children: [
          {
            path: "main",
            element: <CreMain />,
            loader: ({ params }) => {
              return api({ url: `${API_CONNECTION.URL}api/cpanel/main` });
            },
          },
          {
            path: "profile",
            element: <CreProfile />,
            loader: ({ params }) => {
              return api({ url: `${API_CONNECTION.URL}api/cpanel/profile` });
            },
          },
          {
            path: "links",
            element: <CreLinks />,
            loader: ({ params }) => {
              return api({ url: `${API_CONNECTION.URL}api/cpanel/links` });
            },
          },
          {
            path: "fans",
            element: <CreFans />,
            loader: ({ params }) => {
              return api({ url: `${API_CONNECTION.URL}api/cpanel/fans` });
            },
          },
          {
            path: "req",
            element: <CreReqs />,
            loader: ({ params }) => {
              return api({ url: `${API_CONNECTION.URL}api/cpanel/question` });
            },
          },
          {
            path: "wish",
            element: <CreWish />,
            loader: ({ request, params }) => {
              const queryParams = new URL(request.url).searchParams;
              const refpret = queryParams.get("refpret");
              return api({
                url: `${API_CONNECTION.URL}api/cpanel/wish${
                  refpret ? "?refpret=" + encodeURIComponent(refpret) : ""
                }`,
              });
            },
          },
          {
            path: "req/total/:id",
            element: <CreReqsTotal />,
            loader: ({ params }) => {
              return api({
                url: `${API_CONNECTION.URL}api/cpanel/question/total/${params.id}`,
              });
            },
          },
          {
            path: "urls",
            element: <CreUrls />,
            loader: ({ params }) => {
              return api({ url: `${API_CONNECTION.URL}api/cpanel/urls` });
            },
          },
          {
            path: "platforms",
            element: <CrePlatforms />,
            loader: ({ params }) => {
              return api({ url: `${API_CONNECTION.URL}api/cpanel/platforms` });
            },
          },
          {
            path: "settings",
            element: <CreSettings />,
            loader: ({ params }) => {
              return api({ url: `${API_CONNECTION.URL}api/cpanel/settings` });
            },
          },
          {
            path: "fan_setting",
            element: <CreFanSetting />,
            loader: ({ params }) => {
              return api({
                url: `${API_CONNECTION.URL}api/cpanel/fan_setting`,
              });
            },
          },
          {
            path: "login_setting",
            element: <CreLoginSetting />,
            loader: ({ params }) => {
              return api({
                url: `${API_CONNECTION.URL}api/cpanel/login_setting`,
              });
            },
          },
          {
            path: "opchat",
            element: <CreOpchat />,
            loader: ({ params }) => {
              return api({ url: `${API_CONNECTION.URL}api/cpanel/opchat` });
            },
          },
          {
            path: "mesreservs",
            element: <CreOpchatReservs />,
            loader: ({ params }) => {
              return api({ url: `${API_CONNECTION.URL}api/cpanel/mesreservs` });
            },
          },
          {
            path: "opchat_blocks",
            element: <CreOpchatBlocks />,
            loader: ({ params }) => {
              return api({
                url: `${API_CONNECTION.URL}api/cpanel/opchat_blocks`,
              });
            },
          },
          {
            path: "wishlist",
            element: <CreWishlist />,
            loader: ({ params }) => {
              return api({
                url: `${API_CONNECTION.URL}api/cpanel/wishlist`,
              });
            },
          },
          {
            path: "quit",
            element: <CreQuit />,
            loader: ({ params }) => {
              return api({
                url: `${API_CONNECTION.URL}api/cpanel/stop`,
              });
            },
          },

          {
            path: "test",
            element: <CreTest />,
          },
        ],
      },
      {
        path: "f/:urlCode?",
        element: <FreeMain />,
        loader: ({ params }) => {
          return api({
            url: `${API_CONNECTION.URL}api/free/${
              params.urlCode ? encodeURIComponent(params.urlCode) : "0"
            }`,
          });
        },
      },
      {
        path: "p/:urlCode?",
        element: <PrmMain />,
        loader: ({ params }) => {
          return api({
            url: `${API_CONNECTION.URL}api/premium/${
              params.urlCode ? encodeURIComponent(params.urlCode) : "0"
            }`,
          });
        },
      },
      {
        path: "premium/:creatorCode?",
        element: <PrmMain />,
        loader: ({ request, params }) => {
          const queryParams = new URL(request.url).searchParams;
          const cremode = queryParams.get("cremode");
          return api({
            url: `${API_CONNECTION.URL}api/premium/${
              params.creatorCode ? params.creatorCode : "0"
            }${cremode ? "?cremode=1" : ""}`,
          });
        },
      },
      {
        path: "premium/chat/:function/:creatorCode?",
        element: <PrmChat />,
        loader: ({ request, params }) => {
          const queryParams = new URL(request.url).searchParams;
          const cremode = queryParams.get("cremode");
          return api({
            url: `${API_CONNECTION.URL}api/premium/chat/${
              params.function ? params.function : "0"
            }/${params.creatorCode ? params.creatorCode : "0"}${
              cremode ? "?cremode=1" : ""
            }`,
          });
        },
      },
      {
        path: "premium/reqtotal/:creatorCode?/:id",
        element: <PrmReqsTotal />,
        loader: ({ request, params }) => {
          const queryParams = new URL(request.url).searchParams;
          const cremode = queryParams.get("cremode");
          return api({
            url: `${API_CONNECTION.URL}api/premium/reqtotal/${
              params.creatorCode ? params.creatorCode : "0"
            }/${params.id ? params.id : ""}${cremode ? "?cremode=1" : ""}`,
          });
        },
      },
      {
        path: "premium/message/:creatorCode?",
        element: <PrmOpChat />,
        loader: ({ request, params }) => {
          const queryParams = new URL(request.url).searchParams;
          const cremode = queryParams.get("cremode");
          return api({
            url: `${API_CONNECTION.URL}api/premium/group/messages/${
              params.creatorCode ? params.creatorCode : "0"
            }?init=1${cremode ? "&cremode=1" : ""}`,
          });
        },
      },
      {
        path: "office/contact/:section?",
        element: <Contact />,
        loader: ({ params }) => {
          return api({
            url: `${API_CONNECTION.URL}api/office/contact/${
              params.section ? params.section : ""
            }`,
          });
        },
      },
      {
        path: "cre/entry/:urlCode?",
        element: <CreEntry />,
        loader: ({ params }) => {
          return api({
            url: `${API_CONNECTION.URL}api/cre/entry/${
              params.urlCode ? params.urlCode : "0"
            }`,
          });
        },
      },
      {
        path: "cre/token",
        element: <CreToken />,
        loader: ({ params }) => {
          return api({ url: `${API_CONNECTION.URL}api/cre/token` });
        },
      },
      {
        path: "cre/init",
        element: <CreInit />,
        loader: ({ params }) => {
          return api({ url: `${API_CONNECTION.URL}api/cre/init` });
        },
      },
      {
        path: "cre/pwresetmail",
        element: <CrePwResetMail />,
        loader: ({ params }) => {
          return api({ url: `${API_CONNECTION.URL}api/cre/pw_reset_mail` });
        },
      },
      {
        path: "cre/pwreset/:token",
        element: <CrePwReset />,
        loader: ({ params }) => {
          return api({
            url: `${API_CONNECTION.URL}api/cre/pw_reset/${params.token}`,
          });
        },
      },
      {
        path: "cre/login/:urlCode?",
        element: <CreLogin />,
        loader: ({ params }) => {
          return api({
            url: `${API_CONNECTION.URL}api/cre/auth/${
              params.urlCode ? params.urlCode : "0"
            }`,
          });
        },
      },
      {
        path: "cre/cya",
        element: <CreCya />,
      },

      {
        path: "mpanel/",
        element: <Mpanel />,
        loader: ({ params }) => {
          return api({ url: `${API_CONNECTION.URL}api/mpanel/user` });
        },
        children: [
          {
            path: "main",
            element: <MemMain />,
            loader: ({ params }) => {
              return api({ url: `${API_CONNECTION.URL}api/mpanel/main` });
            },
          },
          {
            path: "profile",
            element: <MemProfile />,
            loader: ({ params }) => {
              return api({ url: `${API_CONNECTION.URL}api/mpanel/profile` });
            },
          },
          {
            path: "pay",
            element: <MemPayment />,
            loader: ({ params }) => {
              return api({ url: `${API_CONNECTION.URL}api/mpanel/pay` });
            },
          },
          {
            path: "plan/:creatorCode?",
            element: <MemPlanChange />,
            loader: ({ params }) => {
              return api({
                url: `${API_CONNECTION.URL}api/mpanel/plan/${
                  params.creatorCode ? params.creatorCode : "0"
                }`,
              });
            },
          },
          {
            path: "restart/:creatorCode?",
            element: <MemPlanRestart />,
            loader: ({ params }) => {
              return api({
                url: `${API_CONNECTION.URL}api/mpanel/plan_return/${
                  params.creatorCode ? params.creatorCode : "0"
                }`,
              });
            },
          },
          {
            path: "welcome/:creatorCode?",
            element: <MemWelcome />,
            loader: ({ params }) => {
              return api({
                url: `${API_CONNECTION.URL}api/mpanel/welcome/${
                  params.creatorCode ? params.creatorCode : "0"
                }`,
              });
            },
          },
          {
            path: "entry_plan/:creatorCode?",
            element: <MemPlanEntry />,
            loader: ({ params }) => {
              return api({
                url: `${API_CONNECTION.URL}api/mpanel/entry_plan/${
                  params.creatorCode ? params.creatorCode : "0"
                }`,
              });
            },
          },
          {
            path: "quit",
            element: <MemQuit />,
            loader: ({ params }) => {
              return api({
                url: `${API_CONNECTION.URL}api/mpanel/stop`,
              });
            },
          },
        ],
      },

      {
        path: "me/service/:urlCode?",
        element: <MemService />,
        loader: ({ params }) => {
          return api({
            url: `${API_CONNECTION.URL}api/me/service/${
              params.urlCode ? encodeURIComponent(params.urlCode) : "0"
            }`,
          });
        },
      },
      {
        path: "me/entry/:creatorCode?",
        element: <MemEntry />,
        loader: ({ params }) => {
          return api({
            url: `${API_CONNECTION.URL}api/me/entry/${
              params.creatorCode ? params.creatorCode : "0"
            }`,
          });
        },
      },
      {
        path: "me/token/:creatorCode?",
        element: <MemToken />,
        loader: ({ params }) => {
          return api({
            url: `${API_CONNECTION.URL}api/me/token/${
              params.creatorCode ? params.creatorCode : "0"
            }`,
          });
        },
      },
      {
        path: "me/init",
        element: <MemInit />,
        loader: ({ params }) => {
          return api({ url: `${API_CONNECTION.URL}api/me/init` });
        },
      },
      {
        path: "me/pwresetmail",
        element: <MemPwResetMail />,
        loader: ({ params }) => {
          return api({ url: `${API_CONNECTION.URL}api/me/pw_reset_mail` });
        },
      },
      {
        path: "me/pwreset/:token",
        element: <MemPwReset />,
        loader: ({ params }) => {
          return api({
            url: `${API_CONNECTION.URL}api/me/pw_reset/${params.token}`,
          });
        },
      },
      {
        path: "me/login/:creatorCode?",
        element: <MemLogin />,
        loader: ({ params }) => {
          return api({
            url: `${API_CONNECTION.URL}api/me/auth/${
              params.creatorCode ? params.creatorCode : "0"
            }`,
          });
        },
      },
      {
        path: "me/cya",
        element: <MemCya />,
      },
      {
        path: "*", // 存在しないパスのためのワイルドカード
        element: <GenError errCode="06" />,
      },
    ],
  },
  {
    //ERROR
    path: "/err/:errCode",
    element: <GenError />,
  },
  {
    path: "/sample/loader",
    element: <LoaderSmaple />,
  },
  {
    path: "/sample/dialog",
    element: <DialogSmaple />,
  },
];

export default router;
